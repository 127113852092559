import React, { useState, useEffect } from 'react'
import { useTheme } from 'next-themes'
import { DarkModeSwitch } from 'react-toggle-dark-mode'

export default function ThemeToggler() {
  const { resolvedTheme, setTheme } = useTheme()

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [resolvedTheme])

  if (!mounted)
    // dummy return so the page layout doesn't shift on load
    return <div className="inline-flex" style={{ width: 25, height: 25 }}></div>

  const toggleDarkMode = (checked) => {
    setTheme(checked ? 'dark' : 'light')
  }

  return (
    <DarkModeSwitch
      checked={resolvedTheme === 'dark'}
      onChange={toggleDarkMode}
      size={25}
    />
  )
}
