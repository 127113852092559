import Head from 'next/head'
import Image from 'next/image'
import { NextSeo } from 'next-seo'

import Header from './header'
import Footer from './footer'

import 'inter-ui/inter.css'

export const siteName = 'James Mugliston'

// Use a really basic image loader so we can export the site
export const imageLoader = ({ src }) => src

export default function Layout({ children, home = false }) {
  return (
    <>
      <Header />
      <div className="my-3 px-4 w-100 sm:max-w-xl md:w-3/4 md:max-w-3xl mx-auto">
        <Head>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <NextSeo
          title={siteName}
          description="A personal blog website."
          openGraph={{
            siteName: siteName,
            title: siteName,
            description: 'A personal blog website.',
            images: [
              {
                url: '/images/profile.webp',
                width: 144,
                height: 144,
                alt: siteName,
              },
            ],
          }}
        />
        {home && (
          <div className="space-y-2 mt-8 text-center">
            <Image
              unoptimized
              loader={({ src }) => src}
              priority
              src="/images/profile.webp"
              className="block mx-auto rounded-full sm:shrink-0"
              height={144}
              width={144}
              alt={siteName}
            />
            <h1 className="text-xl font-medium py-2">{siteName}</h1>
          </div>
        )}
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}
